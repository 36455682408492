import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from "@apollo/client";
import GroupLabel from '../subcomponents/GroupLabel';
import { day_translate } from "../../../utils/translate";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, FormControl, Select, InputLabel, MenuItem, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import ActivityListConfirmDelete from './ActivityListConfirmDelete';
import { DeleteIcon, EditIcon, StudentIcon, FillIcon, ConflictIcon } from "../../Icons";
import CUActivity from "../CreateUpdateComponents/Activity";
import CUModal from '../modals/CUModal';
import moment from "moment";
import { COPY_ACTIVITY, LIST_PERIODS, GET_NB_CONCURENT_ACTIVITY_FOR_ACTIVITY } from '../../../graphql/admin-requests';
import { useSnackbar } from 'notistack';
import { castPeriodDate } from "../../../utils/time";
import UserByActivityList from '../UserByActivityList/UserByActivityList';
import CustomIconButton from "../subcomponents/CustomIconButton"
import FillActivity from '../modals/FillActivity';
import FillActivityForGroups from '../modals/FillActivityForGroups';
import ClassroomGradeList from '../subcomponents/ClassroomGradeList';
import ActivityConflicts from '../ActivityConflicts/ActivityConflicts';


const ActivityListResults = ({ user, adminView, activities, totalCount, setLimit, limit, setOffset, offset, page, setPage, handleDeleteActivity, loadData, grades, ...rest }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [selectedActivityIds, setSelectedActivityIds] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openCopyDialog, setOpenCopyDialog] = useState(false);
    const [toDelete, setToDelete] = useState({});
    const [progress, setProgress] = useState(0);
    const [confirmed, setConfirmed] = useState(false);
    const [periods, setPeriods] = useState([]);
    const [periodToCopy, setPeriodToCopy] = useState("");


    const [openUserListModal, setOpenUserListModal] = useState(false);
    const [openFillActivityModal, setOpenFillActivityModal] = useState(false);
    const [openFillActivityForGroupsModal, setOpenFillActivityForGroupsModal] = useState(false);
    const [openConflictModal, setOpenConflictModal] = useState(false);
    const [activityUid, setActivityUid] = useState("");
    const [activityToFill, setActivityToFill] = useState({});
    const [activityToCheck, setActivityToCheck] = useState({});

    const [openEditModal, setOpenEditModal] = useState(false);
    const [activityToUpdate, setActivityToUpdate] = useState({});

    const [getConflicts] = useLazyQuery(GET_NB_CONCURENT_ACTIVITY_FOR_ACTIVITY, {
        onCompleted: (data) => {
            const res = data.getConcurentActivityForActivity.totalCount;
            if (res > 0) {
                enqueueSnackbar("Conflits existants pour cette activité", { variant: "warning" });
                setOpenConflictModal(true);
            } else {
                enqueueSnackbar("Pas de conflits existants pour cette activité", { variant: "success" })
                loadData();
            }
        },
        onError: e => { enqueueSnackbar("Impossible de se verifier les conflits", { variant: "error" }) },
        fetchPolicy: "cache-and-network"
    });

    const handleSelectAll = (event) => {
        let newSelectedActivityIds;

        if (event.target.checked) {
            newSelectedActivityIds = activities.map((activity) => activity.uid);
        } else {
            newSelectedActivityIds = [];
        }

        setSelectedActivityIds(newSelectedActivityIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedActivityIds.indexOf(id);
        let newSelectedActivityIds = [];

        if (selectedIndex === -1) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds, id);
        } else if (selectedIndex === 0) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds.slice(1));
        } else if (selectedIndex === selectedActivityIds.length - 1) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedActivityIds = newSelectedActivityIds.concat(
                selectedActivityIds.slice(0, selectedIndex),
                selectedActivityIds.slice(selectedIndex + 1)
            );
        }

        setSelectedActivityIds(newSelectedActivityIds);
    };

    const handleLimitChange = async (event) => {
        await setLimit(event.target.value);
    };

    const openConfirmDelete = (activity) => {
        setToDelete(activity);
        setOpenDialog(true);
    }

    const closeDialog = () => setOpenDialog(false);
    const closeCopyDialog = () => setOpenCopyDialog(false);

    const handlePageChange = async (event, newPage) => {
        await setOffset(newPage * limit);
        await setPage(newPage);
    };

    const [copyActivity] = useMutation(COPY_ACTIVITY, {
        onCompleted: (data) => {
            console.log("1 activité copiée", data);
            return Promise.resolve("1  activité copiée");
        },
        onError: (error) => {
            enqueueSnackbar("Impossible de copier l'activité")
            return Promise.reject("Impossible de copier l'activité");
        },
    });


    const confirmCopyAll = async () => {
        setConfirmed(true);

        let i = 1;
        const tasks = selectedActivityIds.map(uid => [i++, () =>
            new Promise((res) => {
                setTimeout(async () => {
                    await copyActivity({ variables: { activityUid: uid, periodUid: periodToCopy } });
                    res("ok");
                }, 200);
            })]);

        for (let [k, task] of tasks) {
            setProgress(100 / tasks.length * k)
            await task();
        };

        loadData();
        closeCopyDialog();
        setProgress(0);
        setConfirmed(false);

    }

    const copyAll = () => {
        setOpenCopyDialog(true);
    }

    const showUserListModal = (uid) => {
        setActivityUid(uid);
        setOpenUserListModal(true);
    }

    const showConflictModal = (activity) => {
        setActivityToCheck(activity);
        setOpenConflictModal(true);
    }

    const showFillActivityModal = activity => {
        setActivityToFill(activity);
        setOpenFillActivityModal(true);
    }

    const showFillActivityForGroupsModal = activity => {
        setActivityToFill(activity);
        setOpenFillActivityForGroupsModal(true);
    }

    const [listPeriods] = useLazyQuery(LIST_PERIODS, {
        onCompleted: (data) => setPeriods(data.allPeriods.nodes),
        onError: (error) => console.error("Error requesting list of periods", error),
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        // Retrieve all periods
        if (openCopyDialog) { listPeriods(); }
    }, [openCopyDialog, listPeriods])


    const checkConflicts = async (activity) => {
        setActivityToCheck(activity);
        await getConflicts({ variables: { activityUid: activity.uid } })
    }

    const getColorForActivity = activity => {
        if (activity.taken === "0") {
            return "error";
        } else if (activity.remaining === "0") {
            return "success";
        } else {
            return "primary";
        }
    }

    return <>
        {openUserListModal && <CUModal open={openUserListModal} activityUid={activityUid} handleClose={() => setOpenUserListModal(false)} component={UserByActivityList} />}
        {openFillActivityModal && <CUModal open={openFillActivityModal} activity={activityToFill} handleClose={() => { setOpenFillActivityModal(false); loadData(); }} component={FillActivity} />}
        {openFillActivityForGroupsModal && <CUModal open={openFillActivityForGroupsModal} activity={activityToFill} handleClose={() => { setOpenFillActivityForGroupsModal(false); loadData(); }} component={FillActivityForGroups} />}
        {openConflictModal && <CUModal open={openConflictModal} activityToCheck={activityToCheck} handleClose={() => { setOpenConflictModal(false); loadData(); }} component={ActivityConflicts} />}
        <ActivityListConfirmDelete
            open={openDialog}
            activity={toDelete}
            onClose={closeDialog}
            onConfirm={() => handleDeleteActivity(toDelete.uid)}
        />
        {openCopyDialog &&
            <Dialog
                open={openCopyDialog}
                keepMounted
                onClose={closeCopyDialog}
            >
                <DialogTitle>Copier plusieurs activités</DialogTitle>
                <DialogContent>
                    {confirmed ?
                        <LinearProgress variant="determinate" value={progress} /> :
                        <>
                            <DialogContentText>
                                Selectionnez la periode dans laquelle vous souhaitez <strong>copier {selectedActivityIds.length} activités</strong>.<br />
                            </DialogContentText>

                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="period">Periode</InputLabel>
                                <Select
                                    fullWidth={true}
                                    labelid="period"
                                    label="Periode"
                                    id="period"
                                    value={periodToCopy}
                                    onChange={(event) => setPeriodToCopy(event.target.value)}
                                >
                                    {periods.map(p => <MenuItem key={p.uid} value={p.uid}>{p.name} ({castPeriodDate(p.startDate)} - {castPeriodDate(p.endDate)})</MenuItem>)}

                                </Select>
                            </FormControl>
                        </>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCopyDialog} color="primary">Annuler</Button>
                    <Button onClick={confirmCopyAll} color="primary">Confirmer</Button>
                </DialogActions>
            </Dialog>}

        {activityToUpdate.uid && <CUModal open={openEditModal} adminView={adminView} user={user} actionTitle="Modifier" component={CUActivity} handleClose={() => { setOpenEditModal(false); !adminView && checkConflicts(activityToUpdate); adminView && loadData(); }} toUpdate={activityToUpdate} />}
        <Card sx={{ display: { xs: 'none', lg: 'block' } }} {...rest}>
            {!adminView && selectedActivityIds.length >= 1 && <Button onClick={copyAll}>Copier les activités</Button>}
            <PerfectScrollbar>
                <Box>
                    <Table size="small" >
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedActivityIds.length === activities.length}
                                        color="primary"
                                        indeterminate={
                                            selectedActivityIds.length > 0
                                            && selectedActivityIds.length < activities.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell>Nom</TableCell>
                                <TableCell>Jour</TableCell>
                                <TableCell>Heures</TableCell>
                                <TableCell>Classes</TableCell>
                                <TableCell style={{ maxWidth: 200 }}>Groupes</TableCell>
                                <TableCell>Salle</TableCell>
                                <TableCell>Organisateur</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activities.slice(0, limit).map((activity) => (
                                <TableRow hover key={activity.uid} selected={selectedActivityIds.indexOf(activity.uid) !== -1}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedActivityIds.indexOf(activity.uid) !== -1}
                                            onChange={(event) => handleSelectOne(event, activity.uid)}
                                            value="true"
                                        />
                                    </TableCell>
                                    <TableCell style={{ minWidth: 125 }}>{activity.name}
                                        <LinearProgress
                                            variant="determinate" value={parseInt(activity.taken) / parseInt(activity.capacity) * 100} />
                                    </TableCell>
                                    <TableCell>{day_translate.FR[activity.day]}</TableCell>
                                    <TableCell style={{ minWidth: 125 }}>{moment(activity.startTime, "HH:mm:00").format("HH:mm")} - {moment(activity.endTime, "HH:mm:00").format("HH:mm")}</TableCell>
                                    <TableCell>
                                        <ClassroomGradeList
                                            classrooms={activity.activityClassroomsByActivityUid.nodes
                                                .map(({ classroomByClassroomUid }) => classroomByClassroomUid)}
                                            grades={grades} />
                                    </TableCell>
                                    <TableCell >{activity.activityGroupsByActivityUid.nodes.map(({ groupByGroupUid }) =>
                                        <GroupLabel key={groupByGroupUid.uid} name={groupByGroupUid.name} />)}
                                    </TableCell>
                                    <TableCell>{activity.placeByPlaceUid && activity.placeByPlaceUid.name}</TableCell>
                                    <TableCell>{activity.userByOrganizerUid ? (`${activity.userByOrganizerUid.firstName} ${activity.userByOrganizerUid.lastName}`) : ''}</TableCell>
                                    <TableCell>
                                        <CustomIconButton
                                            title="Elèves"
                                            button={<Button onClick={() => showUserListModal(activity.uid)} size="small" color={getColorForActivity(activity)} variant="outlined" endIcon={<StudentIcon />}>{activity.taken} / {activity.capacity}</Button>} />
                                        {!adminView && <CustomIconButton
                                            onClick={() => showConflictModal(activity)}
                                            title="Conflits"
                                            icon={<ConflictIcon />} />}
                                        {!adminView && <CustomIconButton
                                            onClick={() => {
                                                showFillActivityModal(activity);
                                            }}
                                            title="Remplir (classes)"
                                            icon={<FillIcon />} />}
                                        {!adminView && <CustomIconButton
                                            onClick={() => {
                                                showFillActivityForGroupsModal(activity);
                                            }}
                                            title="Remplir (groupes)"
                                            icon={<FillIcon />} />}
                                        {(!adminView
                                            || (adminView && (activity && activity.userByOrganizerUid && activity.userByOrganizerUid.uid === user.uid)))
                                            && <CustomIconButton
                                                onClick={() => {
                                                    setActivityToUpdate(activity);
                                                    setOpenEditModal(true);
                                                }}
                                                title="Modifier"
                                                icon={<EditIcon />} />}
                                        {!adminView && <CustomIconButton
                                            onClick={() => openConfirmDelete(activity)}
                                            title="Supprimer"
                                            icon={<DeleteIcon />} />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
        </Card>
        <Card sx={{ display: { xs: 'block', lg: 'none' } }}>
            <Table size="small">
                <TableBody>
                    {activities.slice(0, limit).map((activity) => (
                        <TableRow hover key={activity.uid} selected={selectedActivityIds.indexOf(activity.uid) !== -1}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedActivityIds.indexOf(activity.uid) !== -1}
                                    onChange={(event) => handleSelectOne(event, activity.uid)}
                                    value="true"
                                />
                            </TableCell>
                            <TableCell>
                                <strong>{activity.name}</strong><br />
                                {day_translate.FR[activity.day]} : {moment(activity.startTime, "HH:mm:00").format("HH:mm")} - {moment(activity.endTime, "HH:mm:00").format("HH:mm")}<br />
                                Places : {activity.taken} / {activity.capacity}<br />
                                Salle : {activity.placeByPlaceUid && activity.placeByPlaceUid.name}<br />
                                {activity.userByOrganizerUid ? (`Responsable : ${activity.userByOrganizerUid.firstName} ${activity.userByOrganizerUid.lastName}`) : ''}<br />
                                <ClassroomGradeList
                                    classrooms={activity.activityClassroomsByActivityUid.nodes
                                        .map(({ classroomByClassroomUid }) => classroomByClassroomUid)}
                                    grades={grades} />
                                {activity.activityGroupsByActivityUid.nodes.map(({ groupByGroupUid }) =>
                                    <GroupLabel key={groupByGroupUid.uid} name={groupByGroupUid.name} />)}

                            </TableCell>
                            <TableCell>
                                <CustomIconButton
                                    title="Elèves"
                                    button={<Button onClick={() => showUserListModal(activity.uid)} size="small" color={getColorForActivity(activity)} variant="outlined" endIcon={<StudentIcon />}>{activity.taken} / {activity.capacity}</Button>} />
                                {!adminView && <CustomIconButton
                                    onClick={() => showConflictModal(activity)}
                                    title="Conflits"
                                    icon={<ConflictIcon />} />}
                                {!adminView && <CustomIconButton
                                    onClick={() => {
                                        showFillActivityModal(activity);
                                    }}
                                    title="Remplir (classes)"
                                    icon={<FillIcon />} />}
                                {!adminView && <CustomIconButton
                                    onClick={() => {
                                        showFillActivityForGroupsModal(activity);
                                    }}
                                    title="Remplir (groupes)"
                                    icon={<FillIcon />} />}
                                {(!adminView
                                    || (adminView && (activity && activity.userByOrganizerUid && activity.userByOrganizerUid.uid === user.uid)))
                                    &&
                                    <CustomIconButton
                                        onClick={() => {
                                            setActivityToUpdate(activity);
                                            setOpenEditModal(true);
                                        }}
                                        title="Modifier"
                                        icon={<EditIcon />} />}
                                <CustomIconButton
                                    onClick={() => openConfirmDelete(activity)}
                                    title="Supprimer"
                                    icon={<DeleteIcon />} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card >
        <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
    </ >;
};

ActivityListResults.propTypes = {
    activities: PropTypes.array.isRequired
};

export default ActivityListResults;